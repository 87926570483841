<template>
  <div v-loading="loading">
    <el-form :model="form" label-width="150px" ref="ruleForm" :rules="rules" style="margin-bottom:80px;height:1000px">
      <el-card>
        <div slot="header">基本信息</div>
          <el-form-item label="活动名称：" prop="Name">
            <el-input v-model="form.Name" :disabled="form.State==2" placeholder="最多输入20个字" style="width:400px" maxlength="20"></el-input>
          </el-form-item>

          <el-form-item label="活动时间：" prop="IsPermanentActivity" class="err-left err-relative">
            <el-radio-group v-model="form.IsPermanentActivity" :disabled="form.Id!=0">
              <div style="margin-top:13px">
                <el-radio :label="true">永久有效</el-radio>
              
              </div>
              <div style="margin-top:15px;">
                <el-radio :label="false" class="flexRow center-radio">
                  <div style="display:flex">
                    <span style="margin-right:10px;line-height:36px">限时兑换</span>
                    <div style="display:flex">
                      <el-form-item label-width="0px" prop="StartTime" class="no-err-left err-relative">
                        <el-date-picker
                          :disabled="form.State!=0||form.IsPermanentActivity"
                          v-model="form.StartTime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          format="yyyy-MM-dd HH:mm:ss"
                          type="datetime"
                          :picker-options="timeOption"
                          placeholder="开始时间">
                        </el-date-picker>
                      </el-form-item>
                      <span style="margin:0px 10px;line-height:36px">~</span>
                      <el-form-item label-width="0px" prop="EndTime" class="no-err-left err-relative">
                        <el-date-picker
                          :disabled="form.State==2||form.IsPermanentActivity"
                          v-model="form.EndTime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          format="yyyy-MM-dd HH:mm:ss"
                          default-time="23:59:59"
                          type="datetime"
                          :picker-options="timeOption"
                          placeholder="结束时间">
                        </el-date-picker>
                      </el-form-item>
                    </div>
                  </div>
                </el-radio>
              </div>
            </el-radio-group>
          </el-form-item>
          <div class="grayfont" style="margin-top:5px;margin-left:165px">活动创建成功，时间类型不可修改</div>
          
          <el-form-item label="发放总量：" prop="SendCount" style="margin-top:20px">
            <div class="input-disabled" style="display:inline-block;">
              <el-input v-model="form.SendCount" style="width:200px" :disabled="form.Id!=0" placeholder="单次添加请勿超过10000"
              onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
              onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
              </el-input>
              <div class="inputcover" @click="disTips" v-if="form.Id!=0"></div>
            </div>
            <div class="grayfont" style="margin-top:5px">1.兑换码总量设置后，只可增加，不可减少；</div>
            <div class="grayfont">
              2.已保存的活动，请在“积分码活动管理列表-点击兑换码总量数值”，前往兑换码管理列表页增加
            </div>
          </el-form-item>
        
          <el-form-item label="积分数量：" prop="ExchangePoint" style="margin-top:20px">
            <el-input v-model="form.ExchangePoint" style="width:200px"  :disabled="form.State==2"
            onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
            onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
            </el-input>
            <div class="grayfont" style="margin-top:5px">1个兑换码可兑换的积分数量</div>
          </el-form-item>
          
          <!-- <el-form-item label="错误次数限制：" style="margin-top:20px">
            <el-checkbox v-model="form.IsOpenErrorLimit" :disabled="form.State==2" :label="true">开启【当天仅可输错3次】规则</el-checkbox>
            <div class="grayfont" style="margin-top:5px">开启后，每个客户当天若输错3次，将要等到第二天才可继续兑换</div>
          </el-form-item> -->
          
          <el-form-item label="兑换成功跳转页面：" style="margin-top:20px">
            <el-button type="text" @click="shopVisible=true">
              <div class="flexRow">
                <div  class = "ellipsis" style="max-width:200px" v-if="form.PagePath">
                  <span>{{form.PageName}}</span>
                </div>
                <span v-else>请选择链接页面</span>
                <i class = "el-icon-arrow-down" style="margin-left:5px"></i>
              </div>
            </el-button>
            <i class = "el-icon-close" v-if="form.PagePath" @click="clear" style="color:#409eff;margin-left:10px;font-size:14px;cursor: pointer;"></i>
          </el-form-item>
      </el-card>

    </el-form>
    <el-card class="bottom">
      <el-button @click="$router.go(-1)">取消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')" v-if="form.State!=2">保存</el-button>
    </el-card>
    
		<linkModal :showLiveTab="true" :visible="shopVisible" @change="dataChange" @cancel="shopVisible=false"></linkModal>
  </div>
</template>

<script>
import {
  activityPointCodesave,
  activityPointCodeinfo
} from "@/api/sv3.0.0"
import linkModal from '@/views/components/linkModal';
export default {
  components: {
    linkModal
  },
  data () {
    var checkIsPermanentActivity = (rule, value, callback) => {
      if(!this.form.IsPermanentActivity&&this.form.StartTime&&this.form.EndTime){
        if(new Date(this.form.StartTime).getTime()>new Date(this.form.EndTime).getTime()){
          callback(new Error('开始时间不得大于结束时间'))
        }else{
          callback()
        }
      }else{
        callback()
      }
    };
    var checkStartTime = (rule, value, callback) => {
      if(!this.form.IsPermanentActivity&&!value){
        callback(new Error('请完善开始时间'))
      }else{
        callback()
      }
    };
    var checkEndTime = (rule, value, callback) => {
      if(!this.form.IsPermanentActivity&&!value){
        callback(new Error('请完善结束时间'))
      }else{
        callback()
      }
    };
    var checkSendCount = (rule, value, callback) => {
      if(this.form.State!=0){
        callback()
      }
      if(value < 1 || value > 10000 || value!=parseInt(value)){
        callback(new Error('单次添加，数量请控制在1~10000之间，整数'))
      }else{
        callback()
      }
    };
    var checkExchangePoint = (rule, value, callback) => {
      if(value < 1 || value > 99999 || value!=parseInt(value)){
        callback(new Error('数量请设置在1~99999之间，整数'))
      }else{
        callback()
      }
    };
    return {
      loading:false,
      form:{
        Id:0,
        Name:'',
        IsPermanentActivity:true,
        StartTime:'',
        EndTime:'',
        SendCount:'',
        ExchangePoint:'',
        IsOpenErrorLimit:true,
        LinkType:0,
        MenuType:'2',
        PageName:'积分中心',
        PagePath:'pages/pointsCenter/pointsCenter',
        State:0,
      },
      rules:{
        Name:[
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ],
        IsPermanentActivity:[
          { required: true,validator: checkIsPermanentActivity, trigger: 'blur' }
        ],
        StartTime:[
          { required: true,validator: checkStartTime, trigger: 'blur' }
        ],
        EndTime:[
          {required: true, validator: checkEndTime, trigger: 'blur' }
        ],
        SendCount:[
          { required: true,validator: checkSendCount, trigger: 'blur' }
        ],
        ExchangePoint:[
          { required: true,validator: checkExchangePoint, trigger: 'blur' }
        ]
      },
      timeOption:{
        disabledDate:(e)=>{
          return (new Date().getTime() - 1000 * 60 * 60 * 24) > new Date(e).getTime()
        },
      },
      shopVisible:false,
    }
  },
  mounted () {
    if(this.$route.query.Id){
      this.getinfo()
    }
  },
  methods:{
    disTips(){
      // console.log(1)
      if(this.form.Id!=0){
        this.$confirm('已保存的活动，发放总量请前往兑换码明细列表添加。总量只可增加，不可减少。', '提示', {
          confirmButtonText: '前往添加总量',
          cancelButtonText: '关闭',
          type: 'warning'
        }).then(async () => {
          this.$router.push({
            path:'/Customer/pointsCode/data',
            query:{
              Id:this.form.Id,
              Name:encodeURIComponent(this.form.Name)
            }
          })
        }).catch(() => {});
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save()
        } else {
          this.$message.error('请完善活动信息')
          return false;
        }
      });
    },
    async save(){
      try{
        this.loading = true
        let res = await activityPointCodesave({
          info:{
            ...this.form,
            StartTime:this.form.IsPermanentActivity?'':this.form.StartTime,
            EndTime:this.form.IsPermanentActivity?'':this.form.EndTime,
          }
        })
        if(res.IsSuccess){
          this.$message.success('保存成功')
          this.$router.go(-1)
        }
      }finally{
        this.loading = false
      }
    },
    clear(){
      this.form.PageName  = ''
			this.form.PagePath = ''
			this.form.MenuType = ''
    },
    dataChange(e){
      // console.log(e)
      this.form.PageName  = e.text
			this.form.PagePath = this.geturl(e).url
			this.form.MenuType = this.geturl(e).type
      // console.log(this.form.PageName,this.form.PagePath,this.form.MenuType)
    },
    geturl(record){
      let data = {
        url:'',
        type:'',
      }
      if (record.type == "classify") {
        data.url = 'pages/productGroup/productGroup?id=' + record.data.Id
        data.type = 1
      } else if (record.type == "functional") {
        // 全部商品
        data.type = 2
        if (record.data.id == 1) {
          data.url = 'pages/index/index'
        } else if (record.data.id == 2) {
          data.url = 'pages/allProduct/allProduct'
        } else if (record.data.id == 3) {
          // 个人中心
          data.url = 'pages/personalCenter/mine/mine'
        } else if (record.data.id == 4) {
          // 购物车
          data.url = 'pages/shoppingCart/shoppingCart'
        } else if (record.data.id == 5) {
          // 积分中心
          data.url = 'pages/pointsCenter/pointsCenter'
        } else if (record.data.id == 6) {
          // 每日签到
          data.url = 'pages/userSign/userSign'
        } else if (record.data.id == 7) {
          // 客服
          data.url = 'pages/customerService/customerService'
        } else if (record.data.id == 8) {
          // 订单列表
          data.url = 'pages/order/myOrder/myOrder'
        } else if (record.data.id == 9) {
          // 分销礼包
          data.url = 'pages/invitation/invitationCard/invitationCard'
        }else if (record.data.id == 10) {
          // 成为会员
          data.url = 'pageA/pages/openSuccess/openSuccess'
        }else if (record.data.id == 11) {
          // 助力直播列表
          data.url = 'pageA/pages/live/live'
        }else if (record.data.id == 12) {
          // 扫码购
          data.url = 'pageA/pages/scan/scan'
        }else if (record.data.id == 13) {
          // 充值中心
          data.url = 'pageA/pages/balanceRecharge/balanceRecharge'
        }else if (record.data.id == 14) {
          // 群分享
          data.url = 'pageA/pages/groupShare/groupShare'
        }else if (record.data.id == 15) {
          // 全部信息页
          data.url = 'pageA/pages/information/information'
        }else if (record.data.id == 16) {
          // 短视频带货
          data.url =  'pageC/pages/shortVideos/record'
        }else if (record.data.id == 17) {
          // 积分码
          data.url = 'pageA/pages/receive-point/receive-point'
        }else if (record.data.id == 18) {
          // 舌诊
          data.url = 'pageC/pages/tongueDiagnosis/home'
        }else if (record.data.id == 19) {
          // 企店即创主页
          data.url = 'pageC/pages/AIGC/home'
        }
      } else if (record.type == "product") {
        data.url = 'pages/detail/detail?id=' + record.data.Id
        data.type = 3
      } else if (record.type == "custom") {
        data.type = 4
        data.url = 'pages/cusPage/cusPage?id=' + record.data.Id
      }else if (record.type == 'truntable'){
        data.type = 6
        data.url = 'pageA/pages/activity/truntable/truntable?id=' + record.data.Id
      } else if (record.type == 'live'){
        data.type = 5
        data.url = 'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=' + record.data.RoomId
      }else if (record.type == 'coupon'){
        data.type = 7
        data.url = 'pages/couponCenter/receive/receive?id='+record.data.Id+'&uid='+(record.data.uid || '')
      }else if (record.type == 'help') {
        data.type = 8
        data.url = 'pages/helpDetail/helpDetail?id=' + record.data.Id + '&activityId=' + record.data.ActivityFullId
      }else if (record.type == 'group') {
        data.type = 9
        data.url = 'pages/groupDetail/groupDetail?id=' + record.data.ProductId + '&fullId=' + record.data.ActivityFullId
      }else if (record.type == 'questionnaire') {
        data.type = 10
        data.url = 'pageA/pages/questionnaire/questionnaire?&referer=2&id=' + record.data.Id;
      }else if (record.type == 'taketest') {
        data.type = 11
        data.url = `pageC/pages/goodnessTest/home?id=` + record.data.Id
      }else if (record.type == 'contentgroup') {
        data.type = 12
        data.url = `pageA/pages/information/informationGroup?id=` + record.data.Id;
      }else if (record.type == 'singlecontent') {
        data.type = 13
        data.url = `pageA/pages/information/detail?id=` + record.data.Id;
      }else if (record.type == 'helpPro') {
        data.type = 14
        data.url = `pageC/pages/helpPro/helpPro?id=` + record.data.ProductId + '&activityId=' + record.data.ActivityFullId;
      }
      return data
    },
    async getinfo(){
      try{
        this.loading = true
        let res = await activityPointCodeinfo({
          Id:this.$route.query.Id
        })
        if(res.IsSuccess){
          let form = res.Result
          // console.log(form)
          if(this.$route.query.type==3){
            form.StartTime = ''
            form.EndTime = ''
            form.SendCount = ''
            form.State = 0
            form.Id = 0
          }
          this.form = form
        }
      }finally{
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.input-disabled{
  position: relative;
  .inputcover{
    // border: 1px solid black;
    position: absolute;
    // background: black;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.center-radio{
  align-items: flex-start !important;
  // border: 1px solid black;
  ::v-deep .el-radio__input{
    margin-top: 11px;
  }
}
.flexRow{
  display: flex;
  align-items: center;
}
.grayfont{
  font-size: 14px;
  color: #999999;
  font-weight: 400;
  line-height: 1.5;
}
.err-relative{
  ::v-deep .el-form-item__error{
    left: 0;
    position: relative;

  }
}
.err-left{
  margin-bottom: 0px;
  ::v-deep .el-form-item__error{
    left: 90px;
  }
  .no-err-left{
    ::v-deep .el-form-item__error{
      left: 0;
    }
  }
}
.bottom{
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 220px;
  right: 0;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1280px){
  .bottom{
    left: 100px;
  }
}
</style>